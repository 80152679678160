import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import Auth from '@/api/endpoints/Auth';
import { useUserStore } from '@/stores/user';
import pinia from '@/stores';
import scrollBehavior from './scrollBehavior';
import { useSurveyStore } from '@/stores/survey';
import { applicationInsights } from '@/plugins/applicationinsights';

const router = createRouter({
  routes,
  linkActiveClass: 'active',
  history: createWebHistory(),
  scrollBehavior,
});

router.beforeEach((to, from) => {
  const isFromPreviewResults = from.meta?.shareableLink;
  const previewTo = `preview-${to.name?.toString()}`;
  const surveyStore = useSurveyStore(pinia);
  if (
    isFromPreviewResults &&
    router.hasRoute(previewTo) &&
    !surveyStore.isMember
  ) {
    return { ...to, name: previewTo };
  }
});

router.beforeEach(async (to, _, next) => {
  const isAuthRoute = to?.meta?.auth;
  const isAuthorizeRoute = to?.meta?.authorize;

  const store = useUserStore(pinia);

  const isAuthenticated = store.isAuthenticated;

  if (isAuthRoute && isAuthenticated) {
    try {
      await Auth.refreshToken();
      next({ name: 'surveys' });
    } catch {
      store.signOut();
    }
  } else if (isAuthorizeRoute) {
    if (isAuthenticated) {
      next();
    } else {
      next({ name: 'sign-in' });
    }
  } else {
    next();
  }
});

router.beforeEach((to, _, next) => {
  const isAdminRoute = to.meta?.admin;
  const userStore = useUserStore();
  if (isAdminRoute && !userStore.isAdmin) {
    next({ name: 'surveys' });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (
    to?.meta?.keepSearchParamsGroup &&
    to.meta.keepSearchParamsGroup === from?.meta?.keepSearchParamsGroup &&
    !Object.entries(to.query).length &&
    Object.entries(from.query).length
  ) {
    next({ ...to, query: from.query });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (
    to?.meta?.keepHashParamsGroup &&
    to.meta.keepHashParamsGroup === from?.meta?.keepHashParamsGroup &&
    !to.hash &&
    from.hash
  ) {
    next({ ...to, hash: from.hash });
  } else {
    next();
  }
});

router.afterEach((to) => {
  window.Intercom?.('update', {
    hide_default_launcher: !!to?.meta?.hideIntercomMessenger,
  });
});

router.afterEach(() => {
  applicationInsights.trackPageView();
});

router.onError((err: Error) => {
  // Reload the app when the bundled files have changed. This indicates a new deployment has happened.
  if (err.message?.includes('Failed to fetch dynamically imported module')) {
    window.location.reload();
  }
});

export default router;
