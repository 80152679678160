import { TierDisplayNames } from '@opinionx/tier';
import { defineStore } from 'pinia';

type Func = () => any | Promise<any>;

export type MessageBoxState = {
  status: boolean;
  title: string | null;
  text: string | null;
  secondaryText: string | null;
  actionText: string | null;
  actionColor: string | null;
  cancelText: string | null;
  cancelColor: string | null;
  imgSrc: string | null;
} & (
  | {
      action: Func | null;
      actionType: 'func';
    }
  | { action?: string; actionType: 'href' }
) &
  (
    | {
        cancelAction: Func | null;
        cancelType: 'func';
      }
    | { cancelAction?: string; cancelType: 'href' }
  );

export const useMessageBoxStore = defineStore('messageBox', {
  state: (): MessageBoxState => ({
    status: false,
    title: null,
    text: null,
    secondaryText: null,
    action: null,
    cancelAction: null,
    actionText: null,
    actionColor: null,
    cancelText: null,
    cancelColor: null,
    imgSrc: null,
    actionType: 'func',
    cancelType: 'func',
  }),
  actions: {
    messageBox({
      title,
      text,
      secondaryText,
      action,
      cancelAction,
      actionText,
      actionColor,
      cancelText,
      cancelColor,
      imgSrc,
    }: {
      title?: string;
      text?: string;
      secondaryText?: string;
      action?: Func | string;
      cancelAction?: Func | string;
      actionText?: string;
      actionColor?: string;
      cancelText?: string;
      cancelColor?: string;
      imgSrc?: string;
    }) {
      this.status = true;
      this.title = title ?? null;
      this.text = text ?? null;
      this.secondaryText = secondaryText ?? null;
      this.action = action ?? null;
      this.actionType = typeof action === 'string' ? 'href' : 'func';
      this.cancelAction = cancelAction ?? null;
      this.cancelType = typeof cancelAction === 'string' ? 'href' : 'func';
      this.actionText = actionText ?? null;
      this.actionColor = actionColor ?? null;
      this.cancelText = cancelText ?? null;
      this.cancelColor = cancelColor ?? null;
      this.imgSrc = imgSrc ?? null;
    },
    upgradeToExportDialog() {
      this.messageBox({
        title: `Exporting is available on the ${TierDisplayNames.scale} tier 💲`,
        text: 'Exporting allows you to send data out of OpinionX as a .csv spreadsheet for further analysis.',
        action: () => this.router.push({ name: 'upgrade' }),
        actionText: `Unlock This Feature ➡️`,
        actionColor: 'primary',
        cancelText: 'Learn more about exports',
        cancelAction: () => {
          window.open(
            'https://knowledge.opinionx.co/en/articles/6764214-exporting-how-to-export-your-opinionx-data-as-a-csv-spreadsheet',
            '_blank',
          );
        },
      });
    },
  },
});
