import { v4 as uuidv4 } from 'uuid';
import Submissions from '@/api/endpoints/Submissions';
import { defineStore } from 'pinia';
import { i18n } from '@/plugins/i18n';
import axios from 'axios';
import { notNull } from '@/helpers/notNull';
import { Type } from '@/constants/Steps';
import { useUserStore } from './user';
import { captureMessage } from '@sentry/vue';
import { useUrlSearchParams } from '@vueuse/core';
import { Finish } from './survey';

const getStorageId = () => {
  const paths = location.pathname.split('/');
  const isPreview = paths[1] === 'preview';
  const publicId = paths[isPreview ? 2 : 1];
  return `${isPreview ? 'preview.' : ''}${publicId}`;
};

export type SubmissionState = {
  _id: string | null;
  clarityId: string | null;
  startTime: number | null;
  initializationTime: number | null;
  time: number | null;
  branding: boolean;
  intro: Record<string, any> | null;
  steps: Record<string, any>[];
  finishes: Finish[];
  currentFinishIndex: number;
  totalCompletedSteps: number;
  currentStepIndex: number;
  completedSteps: (Record<string, any> | null)[];
  stepRouteTaken: number[];
  currentSubStepIndex: number;
  completedSubSteps: Record<string, any>[];
  accessToken: string | null;
  loading: boolean;
  forward: boolean;
  stepState: (Record<string, any> | null)[];
  invite: string | null;
  sessionId: string | null;
  closed: boolean;
  linkUsed: boolean;
  submitted: boolean;
  publicId: string | null;
};

export const useSubmissionStore = defineStore('submission', {
  state: (): SubmissionState => ({
    _id: null,
    clarityId: null,
    startTime: null,
    initializationTime: null,
    time: null,
    branding: false,
    intro: null,
    steps: [],
    finishes: [],
    currentFinishIndex: 0,
    totalCompletedSteps: 0,
    currentStepIndex: 0,
    completedSteps: [],
    stepRouteTaken: [],
    currentSubStepIndex: 0,
    completedSubSteps: [],
    accessToken: null,
    loading: false,
    forward: true,
    stepState: [],
    invite: null,
    sessionId: null,
    closed: false,
    submitted: false,
    linkUsed: false,
    publicId: null,
  }),
  getters: {
    currentStep(state): Record<string, any> | null {
      return state.steps[state.currentStepIndex] ?? null;
    },
    currentStepData(state) {
      return state.completedSteps[state.currentStepIndex] ?? null;
    },
    currentSubStep(): Record<string, any> | null {
      if (!this.currentStep) {
        return null;
      }
      switch (this.currentStep.type) {
        case Type.PAIR_RANK:
        case Type.SIMPLE_PAIR_RANK:
        case Type.AGREEMENT_RANK:
          return (
            this.steps[this.currentStepIndex]?.opinions[
              this.currentSubStepIndex
            ] ?? null
          );
        case Type.PROFILE_RANK:
          return (
            this.steps[this.currentStepIndex]?.votingRounds[
              this.currentSubStepIndex
            ] ?? null
          );
        default:
          return null;
      }
    },
    numberOfCurrentSubStep(): number {
      if (!this.currentStep) {
        return 0;
      }
      switch (this.currentStep.type) {
        case Type.PAIR_RANK:
        case Type.SIMPLE_PAIR_RANK:
        case Type.AGREEMENT_RANK:
        case Type.BEST_WORST_RANK:
          return this.currentStep.opinions.length;
        case Type.PROFILE_RANK:
          return this.currentStep.votingRounds.length;
        default:
          return 0;
      }
    },
    currentStepState(state): Record<string, any> | null {
      return state.stepState[this.currentStepIndex] ?? null;
    },
    completedPercentage(state): number {
      return Math.min(
        state.totalCompletedSteps / Math.max(totalStepsAndSubSteps(), 1),
        1,
      );
    },
    completedPercentageDisplayValue(): number {
      return Math.round(this.completedPercentage * 100);
    },
    hasTimedOut(): boolean {
      const TwentyFourHoursHrsMilliseconds = 1000 * 60 * 60 * 24;

      const sinceInitialization = Date.now() - (this.initializationTime ?? 0);

      return sinceInitialization > TwentyFourHoursHrsMilliseconds;
    },
    finish(state): Finish | null {
      return state.finishes[state.currentFinishIndex] ?? null;
    },
  },
  actions: {
    async findOne(publicId: string) {
      this.$reset();
      await this.navigateToIntro();
      this.publicId = publicId;
      try {
        this.invite = this.router.currentRoute.value.query?.invite
          ? this.router.currentRoute.value.query.invite.toString()
          : null;
        const { data } = await Submissions.findOne(this.publicId, {
          invite: this.invite ?? null,
        });
        i18n.global.locale = data.language;
        this.$state = data;
        this.$patch({ initializationTime: Date.now() });
        if (data.closed) {
          await this.router.replace({ name: 'closed' }).catch(() => {});
        } else if (data.linkUsed) {
          await this.router.replace({ name: 'link-used' }).catch(() => {});
        } else if (this.intro?.skip) {
          await this.start();
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          switch (error.response?.status) {
            case 404:
              await this.router.replace({ name: 'not-found' }).catch(() => {});
              return;
            default:
              throw error;
          }
        }
        throw error;
      }
    },
    async navigateToIntro() {
      if (this.router.currentRoute.value.meta.preview) {
        return;
      }
      if (this.router.currentRoute.value.name !== 'intro') {
        await this.router.replace({ name: 'intro' });
      }
    },
    async goToSurveyHome() {
      if (this.closed) {
        await this.router.replace({ name: 'closed' });
        return;
      } else if (this.linkUsed) {
        await this.router.replace({ name: 'link-used' });
        return;
      }
      await this.router.replace({
        name: this.router.currentRoute.value.meta.preview
          ? 'preview-survey'
          : 'survey',
        query: this.router.currentRoute.value.query,
      });
    },
    async start() {
      try {
        this.startTime = Date.now();
        this.invite = this.router.currentRoute.value.query?.invite
          ? this.router.currentRoute.value.query.invite.toString()
          : null;
        notNull(this.publicId, 'publicId');
        const {
          data: { steps, sessionId },
        } = await Submissions.start(this.publicId, {
          invite: this.invite ?? null,
        });
        this.sessionId = sessionId;
        if (steps.length === 0) {
          await this.router.replace({ name: 'finish' }).catch(() => {});
          return;
        }
        this.steps = steps;
        this.completedSteps = new Array(this.steps.length).fill(null);
        this.stepState = new Array(this.steps.length).fill(null);
        await this.goToSurveyHome();
      } catch (error) {
        if (axios.isAxiosError(error)) {
          switch (error.response?.status) {
            case 403:
              await this.router.replace({ name: 'closed' }).catch(() => {});
              return;
            case 404:
              await this.router.replace({ name: 'not-found' }).catch(() => {});
              return;
            default:
              throw error;
          }
        }
        throw error;
      }
      try {
        const clarityId = uuidv4();
        this.clarityId = clarityId;
        if (window?.clarity) {
          await window?.clarity('identify', clarityId);
          await window?.clarity(
            'set',
            'publicSurveyId',
            this.router.currentRoute.value.params.id,
          );
        }
      } catch (error) {
        captureMessage('Unable to set clarity id during survey start', {
          extra: { error },
        });
      }
    },
    submitStep(
      payload: Record<string, any> | null = null,
      nextStep?: string | null,
    ) {
      this.forward = true;
      this.stepRouteTaken.push(this.currentStepIndex);
      this.totalCompletedSteps++;

      this.completedSteps[this.currentStepIndex] = payload;
      // If finished
      const finishIndex =
        nextStep === 'finish'
          ? 0
          : this.finishes.findIndex(({ _id }) => _id === nextStep);
      if (this.currentStepIndex === this.steps.length - 1) {
        this.currentFinishIndex = finishIndex >= 0 ? finishIndex : 0;
        return this.submit();
      }
      this.currentStepIndex++;

      this.currentSubStepIndex = 0;
      this.completedSubSteps = [];

      // If branching is jump to finish

      if (finishIndex !== -1) {
        this.currentFinishIndex = finishIndex;
        // Overwrite jumped steps with null
        this.completedSteps.fill(null, this.currentStepIndex);
        this.submit();
        return;
      }

      // If branching jump to branch.
      if (nextStep) {
        const nextStepIndex = this.steps.findIndex(
          ({ _id }) => _id === nextStep,
        );
        if (nextStepIndex !== -1 && nextStepIndex >= this.currentStepIndex) {
          // Overwrite jumped steps with null
          this.completedSteps.fill(null, this.currentStepIndex, nextStepIndex);
          this.currentStepIndex = nextStepIndex;
        }
      }

      this.partialSubmit();
    },
    back() {
      this.forward = false;
      this.totalCompletedSteps--;
      if (this.currentSubStepIndex !== 0) {
        this.completedSubSteps?.pop();
        this.currentSubStepIndex--;
        return;
      }

      const lastStep = this.stepRouteTaken.pop();
      if (lastStep === undefined) {
        return;
      }
      this.currentStepIndex = lastStep;

      // If we've returned to a step with substeps set the substep index to the length of votes
      switch (this.currentStep?.type) {
        case Type.PAIR_RANK:
        case Type.SIMPLE_PAIR_RANK:
        case Type.AGREEMENT_RANK:
        case Type.BEST_WORST_RANK:
          this.currentSubStepIndex =
            (this.currentStep?.opinions.length ?? 1) - 1;
          this.completedSubSteps =
            this.completedSteps[this.currentStepIndex]?.votes;
          this.completedSubSteps?.pop();
          break;
        case Type.PROFILE_RANK:
          this.currentSubStepIndex =
            (this.currentStep?.votingRounds.length ?? 1) - 1;
          this.completedSubSteps =
            this.completedSteps[this.currentStepIndex]?.votes;
          this.completedSubSteps?.pop();
          break;
      }
    },
    submitSubStep(vote: Record<string, any>) {
      if (!this.currentStep) {
        return;
      }

      // If there's no data then we can add otherwise overwrite
      if (this.completedSubSteps[this.currentSubStepIndex] === undefined) {
        this.completedSubSteps.push(vote);
      } else {
        this.completedSubSteps[this.currentSubStepIndex] = vote;
      }
      this.currentSubStepIndex++;
      const stepPayload = {
        type: this.currentStep.type,
        step: this.currentStep._id,
        votes: this.completedSubSteps,
      };
      if (this.currentSubStepIndex === this.numberOfCurrentSubStep) {
        this.submitStep(stepPayload, this.currentStep.branch?.nextStep);
      } else {
        this.totalCompletedSteps++;
        this.partialSubmit({ appendToPayload: stepPayload });
      }
    },
    async partialSubmit(options?: { appendToPayload?: Record<string, any> }) {
      if (this.router.currentRoute.value.meta.preview) {
        return;
      }
      notNull(this._id, '_id');
      const steps = this.completedSteps.filter((step) => step !== null);
      if (options?.appendToPayload) {
        steps.push(options.appendToPayload);
      }
      try {
        await Submissions.partial(this._id, {
          sessionId: this.sessionId,
          completedPercentage: this.completedPercentage,
          steps,
          userId: useUserStore()._id,
          invite: this.invite ?? null,
          clarityId: this.clarityId ?? null,
          sessionDuration: this.startTime ? Date.now() - this.startTime : 0,
          enrichmentValues: getURLParamEnrichmentValues(),
        });
      } catch (error) {
        if (!axios.isAxiosError(error)) {
          throw error;
        }
        switch (error.response?.status) {
          case 429:
            return;
          default:
            throw error;
        }
      }
    },
    async submit() {
      if (this.router.currentRoute.value.meta.preview) {
        return this.router.replace({ name: 'preview-finish' }).catch(() => {});
      }
      if (this.loading) {
        return;
      }
      try {
        this.loading = true;
        await this.router.replace({ name: 'finish' }).catch(() => {});
        notNull(this.publicId, 'publicId');
        await Submissions.finish(
          this.publicId,
          {
            steps: this.completedSteps.filter((step) => step !== null),
            enrichmentValues: getURLParamEnrichmentValues(),
          },
          {
            userId: useUserStore()._id,
            invite: this.invite ?? null,
            clarityId: this.clarityId ?? null,
            sessionDuration: this.startTime ? Date.now() - this.startTime : 0,
            sessionId: this.sessionId,
          },
        );
        this.submitted = true;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          switch (error.response?.status) {
            case 429:
              return;
            case 403:
              await this.router.replace({ name: 'closed' }).catch(() => {});
              return;
            default:
              throw error;
          }
        } else {
          throw error;
        }
      } finally {
        this.loading = false;
      }
    },
    setCurrentStepState(payload: Record<string, any> | null) {
      this.stepState[this.currentStepIndex] = payload;
    },
    patchCurrentStepState(payload: Record<string, any> | null) {
      this.stepState[this.currentStepIndex] = {
        ...this.stepState[this.currentStepIndex],
        ...payload,
      };
    },
  },
  persist: {
    enabled: true,
    strategies: [{ key: `submission.${getStorageId()}` }],
  },
});

const subStepsToFinish = (stepIndex: number): number => {
  const state = useSubmissionStore();
  const step = state.steps[stepIndex];

  if (!step) {
    return 0;
  }
  switch (step.type) {
    case Type.PROFILE_RANK:
      return (
        step.votingRounds.length +
        subStepsToFinish(nextStepIndex(step.branch?.nextStep, stepIndex))
      );
    case Type.SIMPLE_PAIR_RANK:
    case Type.PAIR_RANK:
    case Type.AGREEMENT_RANK:
    case Type.BEST_WORST_RANK:
      return (
        step.opinions.length +
        subStepsToFinish(nextStepIndex(step.branch?.nextStep, stepIndex))
      );
    default:
      return (
        1 + subStepsToFinish(nextStepIndex(step.branch?.nextStep, stepIndex))
      );
  }
};

const currentSubstepsToFinish = (): number => {
  const state = useSubmissionStore();
  return (
    subStepsToFinish(state.currentStepIndex) - state.completedSubSteps.length
  );
};

const totalStepsAndSubSteps = (): number => {
  const state = useSubmissionStore();
  return state.totalCompletedSteps + currentSubstepsToFinish();
};

const nextStepIndex = (
  nextStepId: string,
  currentStepIndex: number,
): number => {
  const state = useSubmissionStore();
  if (
    nextStepId === 'finish' ||
    state.finishes.some(({ _id }) => _id === nextStepId)
  ) {
    return -1;
  }
  const nextStepIndex = state.steps.findIndex(({ _id }) => _id === nextStepId);
  if (nextStepIndex > currentStepIndex) {
    return nextStepIndex;
  } else {
    return currentStepIndex + 1;
  }
};

const getURLParamEnrichmentValues = () => {
  const params = useUrlSearchParams('hash-params');
  return Object.entries(params).map(([name, value]) => ({ name, value }));
};
