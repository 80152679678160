<template>
  <v-dialog v-model="messageBoxStore.status" max-width="600px">
    <v-card>
      <v-card-item v-if="messageBoxStore.title">
        <v-card-title class="text-h5">
          {{ messageBoxStore.title }}
        </v-card-title>
      </v-card-item>

      <v-card-text v-if="messageBoxStore.text">
        {{ messageBoxStore.text }}
      </v-card-text>

      <v-card-text v-if="messageBoxStore.imgSrc">
        <v-img :src="messageBoxStore.imgSrc"></v-img>
      </v-card-text>

      <v-card-text v-if="messageBoxStore.secondaryText">
        {{ messageBoxStore.secondaryText }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <template v-if="messageBoxStore.cancelText">
          <v-btn
            v-if="messageBoxStore.cancelType === 'func'"
            :color="
              messageBoxStore.cancelColor ? messageBoxStore.cancelColor : 'gray'
            "
            variant="text"
            @click="cancel"
          >
            {{ messageBoxStore.cancelText }}
          </v-btn>
          <v-btn
            v-else
            :color="
              messageBoxStore.cancelColor ? messageBoxStore.cancelColor : 'gray'
            "
            variant="text"
            target="_blank"
            :href="messageBoxStore.cancelAction"
            @click="cancel"
          >
            {{ messageBoxStore.cancelText }}
          </v-btn>
        </template>
        <v-btn
          v-if="messageBoxStore.actionType === 'func'"
          :color="
            (messageBoxStore.actionColor
              ? messageBoxStore.actionColor
              : 'primary') + ' darken-1'
          "
          variant="flat"
          data-cy="confirm"
          :loading
          @click="ok"
        >
          {{ messageBoxStore.actionText ? messageBoxStore.actionText : 'Ok' }}
        </v-btn>
        <v-btn
          v-if="messageBoxStore.actionType === 'href'"
          :color="
            (messageBoxStore.actionColor
              ? messageBoxStore.actionColor
              : 'primary') + ' darken-1'
          "
          variant="flat"
          data-cy="confirm"
          target="_blank"
          :href="messageBoxStore.action"
        >
          {{ messageBoxStore.actionText ? messageBoxStore.actionText : 'Ok' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { useMessageBoxStore } from '@/stores/messageBox';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MessageBox',
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapStores(useMessageBoxStore),
  },
  methods: {
    async ok() {
      if (this.messageBoxStore.actionType === 'href') {
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        if (this.messageBoxStore.action) {
          await this.messageBoxStore.action();
        }
      } finally {
        this.loading = false;
        this.messageBoxStore.status = false;
      }
    },
    cancel() {
      if (
        this.messageBoxStore.cancelType === 'func' &&
        this.messageBoxStore.cancelAction
      ) {
        this.messageBoxStore.cancelAction();
      }
      this.messageBoxStore.status = false;
    },
  },
});
</script>
