<template>
  <v-snackbar
    v-model="messageStore.status"
    data-cy="message"
    :color="messageStore.type ?? 'info'"
    location="top"
    :timeout="messageStore.duration"
  >
    {{ messageStore.text }}
  </v-snackbar>
</template>

<script setup lang="ts">
import { useMessageStore } from '@/stores/message';

const messageStore = useMessageStore();
</script>
