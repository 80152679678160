import { defineStore } from 'pinia';

export type MessageType = 'success' | 'info' | 'warning' | 'error';

export type MessageState = {
  status: boolean;
  type: MessageType | null;
  text: string | null;
  duration: number | null;
};

export const useMessageStore = defineStore('message', {
  state: (): MessageState => ({
    status: false,
    type: null,
    text: null,
    duration: null,
  }),
  actions: {
    message(type: MessageType, text: string, duration: number = 2000) {
      this.status = true;
      this.type = type;
      this.text = text;
      this.duration = duration;
    },
  },
});
