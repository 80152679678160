export default [
  {
    path: '',
    name: 'sign-in',
    component: () => import('@/views/Auth/SignIn.vue'),
    meta: {
      name: 'Sign In',
      auth: true,
      keepSearchParamsGroup: 'auth',
    },
  },
  {
    path: 'sign-up',
    name: 'sign-up',
    component: () => import('@/views/Auth/SignUp.vue'),
    meta: {
      name: 'Sign Up',
      auth: true,
      keepSearchParamsGroup: 'auth',
    },
  },
  {
    path: 'forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/Auth/ForgotPassword.vue'),
    meta: {
      name: 'Forgot Password',
      auth: false,
    },
  },
  {
    path: 'reset-password',
    name: 'reset-password',
    component: () => import('@/views/Auth/ResetPassword.vue'),
    meta: {
      name: 'Reset Password',
      auth: false,
    },
  },
];
