import { RouteRecordRaw } from 'vue-router';

export default {
  path: 'results',
  name: 'results',
  redirect: { name: 'results-overview' },
  component: () => import('@/views/SurveyDashboard/ResultsTab/ResultsPage.vue'),
  meta: {
    name: 'Survey Results',
    authorize: true,
    user: true,
  },
  children: [
    {
      path: 'overview',
      name: 'results-overview',
      component: () =>
        import(
          '@/views/SurveyDashboard/ResultsTab/OverviewTab/OverviewTab.vue'
        ),
      children: [
        {
          path: 'opinion/:opinionId',
          redirect: { name: 'opinion-details' },
          component: () =>
            import(
              '@/views/SurveyDashboard/ResultsTab/DetailsDrawer/OpinionDetails/OpinionDetailsDrawer.vue'
            ),
          children: [
            {
              path: 'details',
              name: 'opinion-details',
              component: () =>
                import(
                  '@/views/SurveyDashboard/ResultsTab/DetailsDrawer/OpinionDetails/OpinionDetails.vue'
                ),
              meta: {
                name: 'Opinion Details',
              },
            },
          ],
        },
        {
          path: 'participant/:participantId',
          redirect: { name: 'participant-details' },
          component: () =>
            import(
              '@/views/SurveyDashboard/ResultsTab/DetailsDrawer/ParticipantDetails/ParticipantDetailsDrawer.vue'
            ),
          children: [
            {
              path: 'details',
              name: 'participant-details',
              component: () =>
                import(
                  '@/views/SurveyDashboard/ResultsTab/DetailsDrawer/ParticipantDetails/ParticipantDetails.vue'
                ),
              meta: {
                name: 'Participant Details',
              },
            },
          ],
        },
        {
          path: 'imported-participants',
          name: 'imported-participants',
          component: () =>
            import(
              '@/views/SurveyDashboard/ResultsTab/OverviewTab/ParticipantsResults/ImportedParticipants.vue'
            ),
          meta: {
            name: 'Imported Participants',
          },
        },
      ],
    },
    {
      path: 'participants',
      name: 'results-participants',
      component: () =>
        import(
          '@/views/SurveyDashboard/ResultsTab/ParticipantsTab/ParticipantsTab.vue'
        ),
    },
    {
      path: 'segments',
      name: 'results-segments',
      component: () =>
        import(
          '@/views/SurveyDashboard/ResultsTab/SegmentsTab/SegmentsTab.vue'
        ),
    },
    {
      path: 'compare',
      name: 'results-compare',
      component: () =>
        import('@/views/SurveyDashboard/ResultsTab/ComparePage/CompareTab.vue'),
    },
    {
      path: 'cluster',
      name: 'results-cluster',
      component: () =>
        import('@/views/SurveyDashboard/ResultsTab/ClusterTab/ClusterTab.vue'),
    },
  ],
} as RouteRecordRaw;
