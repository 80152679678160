import router from '@/router';
import {
  ClickAnalyticsPlugin,
  type IClickAnalyticsConfiguration,
} from '@microsoft/applicationinsights-clickanalytics-js';
import {
  ApplicationInsights,
  ITelemetryItem,
  PageView,
  PageViewPerformance,
} from '@microsoft/applicationinsights-web';

const clickPluginInstance = new ClickAnalyticsPlugin();

const clickPluginConfig: IClickAnalyticsConfiguration = {
  autoCapture: true,
  dataTags: {
    useDefaultContentNameOrId: true,
  },
  urlCollectQuery: true,
};

export const applicationInsights = new ApplicationInsights({
  config: {
    connectionString: import.meta.env
      .VITE_APPLICATIONINSIGHTS_CONNECTION_STRING,
    extensions: [clickPluginInstance],
    extensionConfig: {
      [clickPluginInstance.identifier]: clickPluginConfig,
    },
    correlationHeaderDomains: [new URL(import.meta.env.VITE_SERVER_HOST).host],
    enableCorsCorrelation: true,
  },
});

const modifyPageNameTelemetryInitializer = (item: ITelemetryItem) => {
  if (
    item.baseType !== PageView.dataType &&
    item.baseType !== PageViewPerformance.dataType
  ) {
    return;
  }
  if (!item.baseData) {
    return;
  }
  if (item.baseData?.uri && typeof item.baseData?.uri === 'string') {
    const path = item.baseData.uri.replace(window.location.origin, '');
    const resolvedRoute = router.resolve(path);
    item.baseData.name = resolvedRoute.name?.toString() ?? resolvedRoute.path;
  }
};

applicationInsights.addTelemetryInitializer(modifyPageNameTelemetryInitializer);
applicationInsights.addTelemetryInitializer(() =>
  ['staging', 'production'].includes(import.meta.env.MODE),
);
